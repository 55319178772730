<template>
  <router-layout>
    <div class="page">
      <div class="header">
        <h2>{{title}}</h2>
        <div class="timeBox">
          <div>
            <span class="iconfont icon-shizhong"></span>
            <span>{{time}}</span>
          </div>
          <!-- <div class="like">
            <van-checkbox v-model="like">
              <template #icon="props">
                <van-icon name="like" color="#f03535" v-if="props.checked" />
                <van-icon name="like-o" v-else />
              </template>
            </van-checkbox>
            <div>收藏</div>
          </div> -->
        </div>
      </div>
      <div class="line"></div>
      <div class="body">
        <div v-html="content"></div>
      </div>
    </div>
  </router-layout>
</template>
<script>
import tabBar from "@/components/tabbar/tabBar";
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      like: false,
      id:'',
      title:'',
      time:'',
      content:''
    };
  },
  mixins: [authMixin],
  components: {
    tabBar
  },
  created(){
    
  },
  activated(){
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods:{
    getDetail(){
      this.$request({
        method: "get",
        url: "cms/news/detail/" + this.id,
      }).then(res => {
        console.log(res)
        if(res.data){
          let data = res.data
          this.title = data.title
          this.time = data.create_time
          this.content = data.content
        }
      })
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.page {
  background: #fff;
  min-height: 100vh;
  padding-bottom: 1.5rem;
  .header {
    padding: 0.55rem 0.3rem;
    border-bottom: 1px solid #eee;
    h2 {
      font-weight: normal;
      font-size: 0.42rem;
      margin-bottom: 0.5rem;
    }
    .timeBox {
      display: flex;
      justify-content: space-between;
      color: #999;
      font-size: 0.34rem;
      height: 0.5rem;
      align-items: center;
      .iconfont {
        margin-right: 0.1rem;
      }
      .like {
        display: flex;
        align-items: center;
        .van-icon {
          font-size: 0.5rem;
          border: none;
          color: #ccc;
          margin-left: 0.05rem;
        }
        .van-checkbox__icon--checked .van-icon {
          background: transparent;
        }
      }
    }
  }
  .line {
    height: 0.2rem;
    background: #f5f5f5;
  }
  .body {
    padding: .8rem .3rem .6rem .3rem;
    p{
      font-size: .36rem !important;
    }
  }
  .dr{
      background: #f5f5f5;
      color: #777;
      padding:.3rem .3rem;
      font-size: .38rem;
    }
  .detail{
    padding: .5rem .3rem;
    font-size: .38rem;
  }
}
</style>